<template>
    <main class="blog-index-page">
        <div class="blog-index-page-header">
            <div class="blog-index-page-header__title-and-subtitle">
                <h1 class="blog-index-page-header__title">
                    <span>{{ $lang.pages.blog.advices }}</span> {{ $lang.pages.blog.and }}
                    <span>{{ $lang.pages.blog.guides }}</span> {{ $lang.pages.blog.to_save_money }}
                </h1>
                <h2 class="blog-index-page-header__subtitle">
                    {{ $lang.pages.blog.best_guides }}
                </h2>
            </div>
        </div>

        <div class="categories">
            <ul class="categories__wrapper">
                <li v-for="(category, index) in info.page.data.categories" :key="index">
                    <LayoutChip path="/blog/" :info="category" />
                </li>
            </ul>
        </div>
        <template v-for="(category, index) in info.page.data.featured_categories">
            <div v-if="category.articles && category.articles.length" :key="index" class="blog-posts">
                <div class="blog-posts__head">
                    <h2 class="blog-posts__title">
                        {{ $lang.pages.blog.articles_about }}
                        <NuxtLink
                            no-prefetch
                            :to="`/${$lang.routes.blog}/${category.slug}`"
                            class="blog-posts__title-category"
                        >
                            {{ category.name }}
                        </NuxtLink>
                    </h2>
                    <NuxtLink
                        no-prefetch
                        :to="`/${$lang.routes.blog}/${category.slug}`"
                        class="blog-posts__see-more"
                        >{{ $lang.pages.blog.see_more }}
                        <img :src="$assets.primary.arrowRight" alt="ver mas" />
                    </NuxtLink>
                </div>
                <ul :class="calculateHowMuchCols(category.name, 'class')">
                    <li v-for="(article, key) in category.articles.slice(0, 3)" :key="key" class="post">
                        <BCardBasic :post="article" :category="category.slug" />
                    </li>
                </ul>
            </div>
        </template>
    </main>
</template>

<script lang="ts" setup>
import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('blog-index-data', () => {
    return $fetch<Api.Responses.Pages.Blog>(endpoints.pages.blog.path, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
    }).catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}
const info = responseData.value

const currentDates = currentDate()

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [info.page.title || '', info.page.description || ''],
)

const Route = useRoute()

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

/* const categories = computed(() => {
    return info.page.data.categories.filter((category: any) => {
        return info.page.data.featured_articles.some((article: any) => {
            return article.category.slug === category.slug
        })
    })
}) */

const calculateHowMuchCols = (category: string, type: string) => {
    if (category === 'Familia' || category === 'Deportes') {
        if (type === 'class') {
            return 'wrapper-2'
        } else return 2
    } else if (category) {
        if (type === 'class') {
            return 'wrapper-3'
        } else return 3
    }
}
</script>

<style lang="postcss" scoped>
.blog-index-page {
    @apply container mb-12 space-y-5;
    &-header {
        @apply mt-5 flex flex-col-reverse flex-wrap justify-between gap-y-2 lg:flex-row;
        &__title-and-subtitle {
            @apply flex flex-col justify-center;
        }
        &__title {
            @apply text-2xl font-semibold text-gray-800;
            span {
                @apply text-site-primary;
            }
        }
        &__subtitle {
            @apply text-gray-800;
        }
        .images-users {
            @apply flex justify-center lg:justify-start;
            &__one {
                @apply absolute right-32 z-20 h-16 w-16;
            }
            &__two {
                @apply absolute right-20 z-30 h-14 w-14;
            }
            &__three {
                @apply absolute right-10 z-40 h-12 w-12;
            }
            &__four {
                @apply absolute right-2 z-50 h-10 w-10;
            }
            picture:deep(img) {
                @apply h-full w-full;
            }
            &__wrapper {
                @apply relative flex h-20 w-52 items-center;
                img {
                    @apply rounded-full border-[#F3F2F5];
                }
            }
        }
    }
    .categories {
        &__wrapper {
            @apply grid grid-cols-2 gap-4 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6;
        }
    }
    .wrapper-2 {
        @apply grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-2;
    }
    .wrapper-3 {
        @apply grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3;
    }
    .blog-posts {
        @apply space-y-5;
        &__head {
            @apply flex justify-between;
        }
        &__title {
            @apply text-xl font-semibold text-gray-800;
            &-category {
                @apply text-site-primary hover:underline;
            }
        }
        &__see-more {
            @apply flex items-center gap-1 font-semibold text-site-primary hover:underline;
            img {
                @apply h-4;
            }
        }
    }
}
</style>
